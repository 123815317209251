.p-chip {
    border-radius: $chipBorderRadius;
    padding: .25rem .75rem;

    .p-chip-text {
        margin-top: 0;
        margin-bottom: 0;
    }

    .p-chip-remove-icon {
        margin-left: .375rem;
    }

    &:has(.p-chip-remove-icon) {
        padding-right: .5rem;
    }

    img {
        margin-left: -.5rem;
    }
}