.p-inputnumber {
    &.p-inputnumber-buttons-stacked {
        position: relative;

        .p-inputnumber-input {
            border-top-right-radius: $borderRadius - 1;
            border-bottom-right-radius: $borderRadius - 1;
        }

        .p-inputnumber-button-group {
            position: absolute;
            top: 1px;
            right: 1px;
            height: calc(100% - 2px);
        }

        .p-inputnumber-button {
            border-top-right-radius: $borderRadius - 1;
            border-bottom-right-radius: $borderRadius - 1;
            background-color: transparent;
            border: 0 none;
            color: $shade500;

            &:hover {
                background-color: $shade100;
                color: $shade600;
            }

            &:active {
                background-color: $shade200;
                color: $shade700;
            }
        }
    }

    &.p-inputnumber-buttons-horizontal {
        .p-inputnumber-button {
            background-color: transparent;
            border: $inputBorder;
            color: $shade500;

            &:hover {
                background-color: $shade100;
                color: $shade600;
            }

            &:active {
                background-color: $shade200;
                color: $shade700;
            }

            &.p-inputnumber-button-up {
                border-left: 0 none;
            }

            &.p-inputnumber-button-down {
                border-right: 0 none;
            }
        }
    }

    &.p-inputnumber-buttons-vertical {
        .p-inputnumber-button {
            background-color: transparent;
            border: $inputBorder;
            color: $shade500;

            &:hover {
                background-color: $shade100;
                color: $shade600;
            }

            &:active {
                background-color: $shade200;
                color: $shade700;
            }

            &.p-inputnumber-button-up {
                border-bottom: 0 none;
            }

            &.p-inputnumber-button-down {
                border-top: 0 none;
            }
        }
    }
}
