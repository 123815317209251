.p-overlaypanel {
    box-shadow: $inputOverlayShadow;
    
    .p-overlaypanel-content {
        padding: .75rem;
    }

    .p-overlaypanel-close {
        position: absolute;
        top: .25rem;
        right: .25rem;

        @include action-icon();
    }
}