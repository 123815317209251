.p-organizationchart {
    .p-organizationchart-node-content {
        padding: 0.75rem 1rem;
        border-radius: $borderRadius;

        .p-node-toggler {
            transition: $actionIconTransition;
            border: 1px solid $organizationChartConnectorColor;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            .p-node-toggler-icon {
                position: static;
            }
        }

        &:has(.p-node-toggler) {
            padding: 0.75rem 1rem 1.25rem 1rem;
        }
    }
}
