.p-panel {
    border: 1px solid $panelHeaderBorderColor;
    border-radius: $borderRadius;
    background-color: $panelContentBg;

    .p-panel-header,
    .p-panel-content,
    .p-panel-footer {
        background: transparent;
        border: 0 none;
    }

    &:has(.p-panel-footer) {
        .p-panel-content {
            padding-bottom: .875rem;
        }
    }
}
