.p-tabmenu {
    .p-tabmenu-ink-bar {
        z-index: 1;
        display: block;
        position: absolute;
        bottom: -1px;
        height: 1px;
        background-color: $primaryColor;
        transition: 250ms cubic-bezier(0.35, 0, 0.25, 1);
    }

    .p-tabmenu-nav {
        position: relative;
        .p-tabmenuitem {
            .p-menuitem-link {
                transition: background-color $transitionDuration, color $transitionDuration, border-color $transitionDuration, box-shadow $transitionDuration, outline-color $transitionDuration;
            }
        }
    }

    .p-tabmenu-nav-container{
        .p-tabmenu-nav-btn {
            outline: 0 none;
        }
    }
}