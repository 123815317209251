.p-password-panel {
    background-color: $inputOverlayBg;
    border: $inputOverlayBorder;
    padding: .75rem;
    box-shadow: $inputOverlayShadow;
    border-radius: $borderRadius;

    .p-password-meter {
        margin-bottom: .75rem;
        background: $passwordMeterBg;
        border-radius: $borderRadius;

        .p-password-strength {
            border-radius: $borderRadius;
            
            &.weak {
                background: $passwordWeakBg;
            }

            &.medium {
                background: $passwordMediumBg;
            }

            &.strong {
                background: $passwordStrongBg;
            }
        }
    }
}
