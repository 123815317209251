.p-toast {
    .p-toast-message {
        backdrop-filter: blur(1.5px);

        .p-toast-message-content {
            .p-toast-detail {
                font-size: 0.875rem;
            }
            
            .p-toast-icon-close {
                width: 1.75rem;
                height: 1.75rem;
                position: relative;
            }
        }
    }
}

.p-toast {
    .p-toast-message.p-toast-message-info {
        box-shadow: 0px 4px 8px 0px rgba(59, 130, 246, 0.04);

        .p-toast-detail {
            color: $textColor;
        }

        .p-toast-icon-close {
            outline-color: $infoMessageIconColor;

            &:hover {
                background: #dbeafe;
            }
        }
    }

    .p-toast-message.p-toast-message-success {
        box-shadow: 0px 4px 8px 0px rgba(34, 197, 94, 0.04);

        .p-toast-detail {
            color: $textColor;
        }

        .p-toast-icon-close {
            outline-color: $successMessageIconColor;

            &:hover {
                background: #dcfce7;
            }
        }
    }

    .p-toast-message.p-toast-message-warn {
        box-shadow: 0px 4px 8px 0px rgba(234, 179, 8, 0.04);

        .p-toast-detail {
            color: $textColor;
        }

        .p-toast-icon-close {
            outline-color: $warningMessageIconColor;

            &:hover {
                background: #fef9c3;
            }
        }
    }

    .p-toast-message.p-toast-message-error {
        box-shadow: 0px 4px 8px 0px rgba(239, 68, 68, 0.04);

        .p-toast-detail {
            color: $textColor;
        }

        .p-toast-icon-close {
            outline-color: $errorMessageIconColor;

            &:hover {
                background: #fee2e2;
            }
        }
    }

    .p-toast-message.p-toast-message-secondary {
        box-shadow: 0px 4px 8px 0px rgba(74, 85, 103, 0.04);

        .p-toast-icon-close {
            outline-color: $errorMessageIconColor;

            &:hover {
                background: $secondaryButtonHoverBg;
            }
        }
    }

    .p-toast-message.p-toast-message-contrast {
        box-shadow: 0px 4px 8px 0px rgba(2, 6, 23, 0.04);

        .p-toast-icon-close {
            outline-color: $errorMessageIconColor;

            &:hover {
                background: $contrastButtonHoverBg;
            }
        }
    }
}
