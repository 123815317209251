.p-cascadeselect {
    box-shadow: $inputShadow;

    .p-cascadeselect-label {
        box-shadow: none;
    }

    &.p-disabled {
        opacity: 1;
        background-color: $disabledInputBg;

        .p-cascadeselect-label {
            color: $textSecondaryColor;
        }
    }
}

div.p-cascadeselect-panel {
    border: 0 none;
    box-shadow: none;
}