.p-accordion {
    .p-accordion-header {
        .p-accordion-header-link {
            border-radius: $borderRadius !important;
            flex-direction: row-reverse;
            justify-content: space-between;

            &:hover {
                border-bottom-color: $accordionHeaderHoverBorderColor;
            }

            .p-accordion-toggle-icon {
                transform: rotate(90deg);
            }
        }

        &.p-highlight {
            .p-accordion-toggle-icon {
                transform: rotate(-180deg);
            }
        }

        &:not(.p-disabled) {
            .p-accordion-header-link {
                &:focus-visible {
                    outline-offset: -2px;
                }
            }
        }
    }

    p-accordiontab {
        .p-accordion-tab {
            border-bottom: 1px solid $shade200;
        }
    }

    p-accordiontab:last-of-type {
        .p-accordion-tab {
            border-bottom: 0 none;
        }
    }
}