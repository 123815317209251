.p-tree {
    border: 0 none;
    padding: 1rem;
    
    .p-tree-container {
        .p-treenode {   
            margin: 2px 0;

            &:focus > .p-treenode-content {
                outline-offset: -1 * $focusOutlineOffset;
            }

            .p-treenode-content {
                &.p-highlight {
                    background: none;
                    .p-tree-toggler {
                        &:hover {
                            background-color: $highlightBg;
                        }
                    }
                }
            }
        }

        > .p-treenode {
            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}