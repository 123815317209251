/***  WIDTHS ***/
@for $i from 1 through 1000 {
  .W#{$i} { width: #{$i}px !important;}
}
@for $i from 1 through 100 {
  .W#{$i}PC { width: unquote("#{$i}%") !important;}
}
.W1280 {width: 1280px !important}
.W50VH {width: 50vh !important}
.W100VH {width: 100vh !important}
.WInitial{width: initial !important}
.swamp {
  position: absolute;
  top: 3px;
  left: 0;
  right: -142px;
  z-index: 1;
}

/***  HEIGHTS ***/
.H100PC{height: 100% !important;}
.H0{height: 0px !important;}
.H19{height: 19px !important;}
.H25{height: 25px !important;}
.H30{height: 30px !important;}
.H32{height: 32px !important;}
.H34{height: 34px !important;}
.H35{height: 35px !important;}
.H36{height: 36px !important;}
.H40{height: 40px !important;}
.H50{height: 50px !important;}
.H54{height: 54px !important;}
.H55{height: 55px !important;}
.H60{height: 60px !important;}
.H62{height: 62px !important;}
.H70{height: 70px !important;}
.H80{height: 80px !important;}
.H85{height: 85px !important;}
.H95{height: 95px !important;}
.H100{height: 100px !important;}
.H109{height: 109px !important;}
.H110{height: 110px !important;}
.H120{height: 120px !important;}
.H130{height: 130px !important;}
.H140{height: 140px !important;}
.H145{height: 145px !important;}
.H146{height: 146px !important;}
.H147{height: 147px !important;}
.H148{height: 148px !important;}
.H149{height: 149px !important;}
.H150{height: 150px !important;}
.H155{height: 155px !important;}
.H160{height: 160px !important;}
.H175{height: 175px !important;}
.H200{height: 200px !important;}
.H210{height: 210px !important;}
.H215{height: 215px !important;}
.H220{height: 220px !important;}
.H230{height: 230px !important;}
.H240{height: 240px !important;}
.H250{height: 250px !important;}
.H260{height: 260px !important;}
.H270{height: 270px !important;}
.H277{height: 277px !important;}
.H280{height: 280px !important;}
.H290{height: 290px !important;}
.H300{height: 300px !important;}
.H400{height: 400px !important;}
.H500{height: 500px !important;}
.H540{height: 540px !important;}
.H600{height: 600px !important;}


/***  MARGINS ***/
@for $i from 0 through 100 {
  .M#{$i} { margin: #{$i}px !important;}
}
@for $i from 0 through 100 {
  .MT#{$i} { margin-top: #{$i}px !important;}
}
@for $i from 0 through 100 {
  .MR#{$i} { margin-right: #{$i}px !important;}
}
@for $i from 0 through 100 {
  .MB#{$i} { margin-bottom: #{$i}px !important;}
}
@for $i from 0 through 100 {
  .ML#{$i} { margin-left: #{$i}px !important;}
}
.MLAUTO{margin-left: auto !important;}
.MA{margin: auto !important;}
.MT-17{margin-top: -17px !important;}


/***  PADDINGS ***/
@for $i from 0 through 50 {
  .P#{$i} { padding: #{$i}px !important;}
}
@for $i from 0 through 100 {
  .PT#{$i} { padding-top: #{$i}px !important;}
}
@for $i from 0 through 100 {
  .PR#{$i} { padding-right: #{$i}px !important;}
}
@for $i from 0 through 100 {
  .PB#{$i} { padding-bottom: #{$i}px !important;}
}
@for $i from 0 through 100 {
  .PL#{$i} { padding-left: #{$i}px !important;}
}

.MXWInitial{max-width: initial !important;}
.MXW100{max-width: 100px !important;}
.MXW200{max-width: 200px !important;}
.MXW250{max-width: 250px !important;}
.MXW300{max-width: 300px !important;}
.MXW310{max-width: 310px !important;}
.MXW320{max-width: 320px !important;}
.MXW330{max-width: 330px !important;}
.MXW340{max-width: 340px !important;}
.MXW350{max-width: 350px !important;}
.MXW360{max-width: 360px !important;}
.MXW380{max-width: 380px !important;}
.MXW400{max-width: 400px !important;}
.MXW450{max-width: 450px !important;}
.MXW500{max-width: 500px !important;}
.MXW550{max-width: 550px !important;}
.MXW600{max-width: 600px !important;}
.MXW650{max-width: 650px !important;}
.MXW700{max-width: 700px !important;}
.MXW750{max-width: 750px !important;}
.MXW800{max-width: 800px !important;}
.MXW850{max-width: 850px !important;}
.MXW900{max-width: 900px !important;}

.MNWInitial{min-width: initial !important;}
.MNW20{min-width: 20px !important;}
.MNW30{min-width: 30px !important;}
.MNW40{min-width: 40px !important;}
.MNW50{min-width: 50px !important;}
.MNW60{min-width: 60px !important;}
.MNW70{min-width: 70px !important;}
.MNW80{min-width: 80px !important;}
.MNW90{min-width: 90px !important;}
.MNW100{min-width: 100px !important;}
.MNW120{min-width: 120px !important;}
.MNW140{min-width: 140px !important;}
.MNW150{min-width: 150px !important;}
.MNW160{min-width: 160px !important;}
.MNW170{min-width: 170px !important;}
.MNW180{min-width: 180px !important;}
.MNW190{min-width: 190px !important;}
.MNW200{min-width: 200px !important;}
.MNW250{min-width: 250px !important;}
.MNW300{min-width: 300px !important;}
.MNW400{min-width: 400px !important;}
.MNW450{min-width: 450px !important;}