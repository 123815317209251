@import 'assets/css/reset.scss';
@import 'assets/css/fonts.scss';
@import 'assets/css/custom-icons.scss';
@import 'assets/css/colors.scss';
@import 'assets/css/sizes.scss';
@import 'primeng/resources/primeng.min.css';
//@import 'primeng/resources/themes/aura-light-blue/theme.css';
@import 'assets/css/client/experian.scss';
//@import 'assets/css/client/santander.scss';
//@import 'assets/css/client/sabadell.scss';
//@import 'assets/css/client/unicaja.scss';
@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';
@import 'assets/css/forms.scss';
@import 'assets/css/tables.scss';
@import 'assets/css/loaders.scss';
@import 'assets/css/opendata.scss';


header .logo-app {
  width: 122px;
  height: 45px;
}

header .logo-app a {
  width: 100%;
  height: 100%;
  display: block;
}

header .logo-app a svg {
  fill: #fff;
}

.main-stop {
  stop-color: #2774D6;
}

.alt-stop {
  stop-color: #E13898;
}

.text-stop {
  stop-color: #0078D7;
}




.logo-client {
  display: block;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  -webkit-text-fill-color: #333;
}

.main-content {
  padding: 25px 40px;
}

.main-content form .p-datatable .p-datatable-wrapper table {
  border: none;
}

.main-content form .p-datatable .p-datatable-header .table-header {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  gap: 10px;
  border: solid 1px $tableBorder;
  border-bottom: none;
  padding: 8px 10px;
}

p-menubar {
  &.ux-admin-p-menubar {
    .p-menubar {
      border: 0;
      border-radius: 0;
      background: transparent;

      p-menubarsub ul li .p-menuitem-link .p-menuitem-icon,
      p-menubarsub ul li .p-menuitem-link .p-menuitem-text {
        color: $white !important;
      }

      .p-menubar-root-list>.p-menuitem>.p-menuitem-link:focus {
        box-shadow: unset;
      }

      .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover {
        background: rgba(0, 0, 0, .08);
      }

      .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):focus {
        border-radius: 0;
        outline: none;
        outline-offset: 0;
        outline-width: 0;
      }

      .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
        color: $white;
        background: rgba(0, 0, 0, .15);
      }

      .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
        color: $white;
        background: rgba(0, 0, 0, .15);
      }

      p-menubarsub {
        ul li a {
          .p-menuitem-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: 'Segoe UI';
            font-weight: 500;
            font-size: 0.75rem;

            .material-symbols-outlined {
              font-size: 2rem;
              font-variation-settings: 'wght' 200;
              color: $white;
            }
          }
        }


      }
    }

    .p-menubar-root-list>.p-menuitem .p-menuitem-link {
      border-bottom: 3px solid transparent;
      padding: 5px;
      margin: 0 10px;
    }

    .p-menubar-root-list>.p-menuitem .p-menuitem-link.p-menuitem-link-active {
      border-bottom-color: var(--link-active);
    }
  }
}


/****OUTLINE BUTTONS****/
button.outline-btn,
button.filled-btn {
  height: 36px;
  line-height: normal;
  border: 2px solid $secondaryBtnBorder;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  text-wrap: nowrap;
  color: $secondaryColor;
  padding: 0 8px 1px 8px;
  background-color: $white;
  position: relative;
  outline: none;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:disabled {
    opacity: 0.35;
  }

  &:not(:disabled):hover {
    background-color: $secondaryColor;
    color: $white;
  }

  &:not(:disabled):hover span {
    color: $white;
  }

  &.filled {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: $white;

    .material-symbols-outlined {
      color: $white;
      font-variation-settings: 'FILL' 1
    }

    &:hover {
      background-color: var(--primary-hover-color);
      border-color: var(--primary-hover-color);
    }
  }

  .material-symbols-outlined {
    display: block;
    width: initial;
    height: initial;
    color: $secondaryBtnBorder;
    font-size: 22px;
    line-height: normal;
    font-variation-settings: 'wght' 300;
    position: relative;
    top: 1px;
  }
}

button.outline-btn.excel-btn {
  border-color: $excelBtn;
  color: $excelBtn;
}

button.outline-btn.excel-btn span {
  color: $excelBtn;
}

button.outline-btn.excel-btn:hover {
  background-color: $excelBtn;
  color: $white;
}

button.outline-btn.excel-btn:hover span {
  color: $white;
}

button.filled-btn {
  color: $white;
  background-color: var(--primary-color);
  border-color: var(--primary-color);

  .material-symbols-outlined {
    color: $white;
  }

  &:not(:disabled):hover {
    background-color: var(--primary-hover-color);
    border-color: var(--primary-hover-color);
  }
}

td.center {
  .outline-btn {
    margin-inline: auto;
  }
}

button.outline-btn.only-text-btn,
button.filled-btn.only-text-btn {
  padding-left: 25px;
  padding-right: 25px;
}

.buttons-row {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 0;

  button.outline-btn,
  button.filled-btn {
    height: 40px;
    border-radius: 4px;
    font-size: .85rem;
    padding: 0 2rem 1px 2rem;
  }
}

.disabled {
  pointer-events: none;
  opacity: 1;
  background: $disabled;
}


.sidebar-header {
  display: flex;
  align-items: center;
  gap: 2rem;

  h6.sidebar-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: $sidebarTitle;
    margin: 0;
    display: flex;
    align-items: center;
  }
}

.iframe-container {
  background-color: $iframeBg;
  padding: 1.5rem;
  margin-top: 1.275rem;

  h5 {
    font-size: 1rem;
    font-weight: 500;
    padding: 5px 15px;
    margin-bottom: 5px;
    margin-top: 0;
  }
}

.info-box {
  background-color: $infoBoxBg;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1.5rem;
  margin: 1rem 0;

  .material-symbols-outlined {
    font-size: 24px;
    font-variation-settings: 'wght' 200;
    color: $secondaryBtnText;
  }

  p {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    color: $secondaryBtnText;
  }
}

.p-sidebar .p-sidebar-header {
  padding: 1.125rem 1.125rem 1.125rem 2rem;
}

.p-sidebar .p-sidebar-content {
  padding: 0 2rem 1.125rem 2rem;
}



.language-selector .p-dropdown-trigger {
  color: var(--primary-color-text);
  width: 20px;
  display: none;
}

.language-selector .p-dropdown-label {
  text-transform: uppercase;
  font-weight: 600;
  color: var(--primary-color-text);
  font-size: 14px;
  padding: 0;
  width: 16px;
  display: block;
  text-align: center;
  line-height: 24px;
  line-height: 20px;
  border: none !important;
  height: 20px !important;
}

.language-selector .p-dropdown.p-component {
  width: 35px;
  padding: 0;
  background: none;
  border: none;
  height: 24px;
  display: flex;
  align-items: center;
  line-height: 16px;
}

.language-selector .p-dropdown-item {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  padding: 7px 11px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  background: $white;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background: $highlightBg;
}

/*** DETAIL detalle ***************************************************************************************************************************************************/

.center {
  text-align: center;
}

.flex-start {
  justify-content: flex-start !important;
}

.flex-between {
  justify-content: space-between !important;
}

.bold400 {
  font-weight: 400;
}

.bold600 {
  font-weight: 600;
}

.align-center {
  align-items: center;
}

.detail-body-buttons {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  padding: 10px 20px 0 20px;
}

.request-detail-buttons {
  margin: 10px 0px 0 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
  // width: calc(100% - 688px);
}

.request-detail-buttons button {
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.detail-header {
  margin-top: 20px;
  background-image: url(assets/images/banner-grey.png);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}

.detail-header * {
  color: $headerTextColor;
}

.detail-header .flex {
  align-items: center;
  gap: 12px;
}

.detail-header>div>div:not(.flex-column) {
  background-color: rgba(255, 255, 255, .8);
  padding: 1px 8px;
  margin-bottom: 5px;
}

.detail-header>div h3 {
  margin: 0px;
  font-size: 18px;
}

.detail-header>div p {
  justify-content: center;
  margin-top: 0;
  font-size: 13px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.date-box {
  background-color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  padding: 0px 15px;
  text-align: center;
  justify-content: center;
  height: 37px;
  display: flex;
  align-items: center;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.document-check span {
  color: $green !important;
  font-size: 26px !important;
}

.document-denied span {
  color: $errorColor !important;
  font-size: 26px !important;
}

.mini-separator {
  width: 5px;
  height: 5px;
  display: block;
  background-color: $secondaryBtnText;
}

.actual-status {
  height: 32px;
  background-color: var(--link-active);
  font-weight: 600;
  color: $white !important;
  font-size: 14px !important;
  display: flex !important;
  line-height: 14px;
  padding: 0 25px;
  border-radius: 20px;
  width: fit-content;
  max-width: 140px;
  text-align: center;
}

.actual-sub-status {
  background-color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  padding: 5px 10px;
  text-align: center;
  justify-content: center;
}

.detail-body {
  display: flex;
  gap: 40px;
  gap: 20px;
  justify-content: space-between;
  padding: 0px 20px 10px 20px;
}

.detail-documents {
  width: calc(100% - 688px);
  border: solid 1px $tableBorder;
  background-color: $white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, .15);
  padding: 20px;
  margin-top: 10px;
}

.detail-documents.relevant-info {
  background-color: #F5F5F5;
}

.detail-documents h2 {
  margin-top: 0;
  border-bottom: solid 2px $tableBorder;
  font-weight: 600;
  font-size: 18px;
  color: $heroTitles;
  padding: 8px;
}

.detail-documents.relevant-info h2 {
  background-color: $white;
}

.detail-content {
  background-color: $detailContentBg;
  padding: 12px 16px;
  border-bottom: solid 2px $detailContentBorder;
}

.detail-content-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detail-content-item:hover {
  background-color: $white;
}

.detail-item-links {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
}

.detail-item-links a,
.detail-item-links button {
  border: solid 1px $tableBtnBorder;
  height: 24px;
  display: flex;
  align-items: center;
  color: $TableBtnIcon;
  text-decoration: none;
  background-color: transparent;
  font-size: 12px;
  padding: 0 8px;
  border-radius: 4px;
}

.detail-item-links a span,
.detail-item-links button span {
  font-size: 18px;
  color: $TableBtnIcon;
  margin-right: 8px;
}

.detail-item-name {
  font-size: 14px;
  height: 32px;
  line-height: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px 0 45px;
  margin: 3px 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.font9 {
  font-size: 9px !important;
}

.font10 {
  font-size: 10px !important;
}

.font11 {
  font-size: 11px !important;
}

.font12 {
  font-size: 12px !important;
}

.font13 {
  font-size: 13px !important;
}

.font14 {
  font-size: 14px !important;
}

.detail-content-header {
  display: flex;
  align-items: center;
}

.detail-content-header span {
  color: $white;
  font-size: 18px;
  padding: 6px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-right: 15px;
}

.custom-doc {
  background-color: var(--secondary-color);
}

.bg-purple {
  background-color: $purple;
}

.bg-blue {
  background-color: $blue;
}

.bg-pink {
  background-color: $pink;
}

.bg-dark-purple {
  background-color: $purpleDark;
}

.detail-content-header h3 {
  font-size: 14px;
  font-weight: 600;
}

.p-tooltip-text {
  padding: 6px 12px;
  font-size: 12px;
}

.p-accordion-tab-active .p-accordion-header-link {
  position: relative;
}

.p-accordion-tab .p-accordion-header-link:after {
  position: absolute;
  top: 14px;
  left: 20px;
  display: block;
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 6px solid $primaryColor;
  border-bottom: 5px solid transparent;
}

.detail-history {
  width: 100px;
  min-width: 100px;
  padding: 40px 5px;
  background-color: $lightGray3;
  margin-top: 10px;
}

.detail-history>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  margin-bottom: 20px;
  width: 100%;
  cursor: pointer;
  padding: 5px 0;
}

.detail-history>div:hover {
  background-color: rgba(120, 120, 180, 0.1);
}

.detail-history>div span {
  font-size: 32px;
  color: $tableThColor;
  font-variation-settings: "wght" 300;
}

.detail-history>div p {
  font-size: 12px;
  margin: 0;
  color: $tableTextColor;
  text-align: center;
}

table {
  border: solid 1px $tableBorder;
  width: 100%;
  border-spacing: inherit;
}

table th,
td {
  font-size: 14px;
  text-align: left;
}

table:not(.p-datepicker-calendar) th,
table:not(.p-datepicker-calendar) td {
  border-bottom: solid 1px $tableBorder;
}

table th {
  background-color: $tableThBg;
  color: $tableThColor;
  font-weight: 600;
}

table:not(.p-datepicker-calendar) th {
  padding: 8px 10px;
}

table td {
  background-color: $white;
  color: $tableTextColor;
}

table:not(.p-datepicker-calendar) td {
  padding: 8px 10px;
}

.bg-lightgray {
  background-color: $lightGray;
}

.bg-lightgray2 {
  background-color: $lightGray2;
}

.table-filter-input {
  position: relative;
}

.table-filter-input .p-inputtext {
  padding-right: 30px;
}

.table-filter-input span.pi-spinner {
  position: absolute;
  top: 9px;
  right: 8px;
}

.p-dropdown .table-filter-input {
  padding-right: 23px;
}

.p-dropdown .table-filter-input span.pi-spinner {
  position: absolute;
  top: 1px;
  right: 0px;
}

.not-found-text {
  width: fit-content;
  background: $white;
  margin: 0 auto;
  padding: 4px 22px;
  border-radius: 14px;
  font-weight: 600;
}

.p-inputtext {
  height: 36px;
  //border-color: $inputBorder;
  color: $inputTextColor;
  font-size: 14px;

  border: solid 1px $inputBorder;
}

.p-inputtext .pi {
  color: $inputTextColor;
}

ul li.p-dropdown-item {
  font-size: 14px;
}

.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: none;
}

/******************/
.p-toast-message-content {
  width: 100%;
  margin-top: 0px;
  display: block;
}

.p-toast-message-content button {
  position: absolute;
  right: 10px;
  top: 7px;
}

.p-toast-message-content h4 {
  font-weight: 600;
  margin-bottom: 8px;
  margin-right: 30px;
  margin-top: 0;
}

.p-toast-message-content p {
  margin-bottom: 12px;
  margin-top: 0;
}

.p-toast-message-content ul {
  margin-top: 0;
  list-style: none;
  padding-left: 0;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.8);
}

.p-toast-message-content ul li {
  margin-top: 0;
  margin-bottom: 4px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: $tableThColor;
}

.p-toast-message-content ul li span {
  color: $errorColor;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

/*****************************************************/
.new-request-radio {
  width: 180px;
  height: 200px;
  display: block;
  position: relative;
  opacity: .83;
}

.new-request-radio:hover,
.new-request-radio.active {
  opacity: 1 !important;
}

.new-request-radio .material-symbols-outlined {
  position: absolute;
  bottom: 4px;
  right: 5px;
  color: $green;
  display: none;
  opacity: 1;
  background: #fff;
  border-radius: 50%;
  padding: 2px;
}

.type-request .new-request-radio .material-symbols-outlined {
  bottom: -27px;
  right: 5px;
  background: #fff;
  border-radius: 50%;
  padding: 0;
}

.new-request-radio.active .material-symbols-outlined {
  display: block;
}

.type-request {
  display: flex;
  justify-content: space-around;
}

.type-request .new-request-radio {
  width: 140px;
  width: 130px;
  height: 125px;
  display: block;
  position: relative;
  background: #f7f7f7;
  opacity: .7;
  filter: grayscale(90%);
}

.select .type-request .new-request-radio {
  opacity: .7;
  filter: none;
}

.select .type-request .new-request-radio:hover {
  opacity: 1;
}

.new-request-radio>.p-element {
  width: 100%;
  height: 100%;
}

.new-request-radio .p-radiobutton {
  width: 100%;
  height: 100%;
  display: block;
  background: url(assets/images/new-form-sprite.png);
}

.new-request-radio:hover {
  box-shadow: 0 8px 5px -5px rgba(0, 0, 0, .1);
}

.new-request-radio.active .p-radiobutton {
  opacity: 1 !important;
  filter: grayscale(0);
  box-shadow: 0 8px 5px -5px rgba(0, 0, 0, .3);
}

.new-request-radio.legal .p-radiobutton {
  background-position: 0 -200px;
}

.new-request-radio.clave .p-radiobutton {
  background-position: -0px -400px;
}

.new-request-radio.customer .p-radiobutton {
  background-position: -0px -650px;
}

.new-request-radio.video .p-radiobutton {
  background-position: -0px -525px;
}

.new-request-radio.sms .p-radiobutton {
  background-position: -0px -775px;
}

.new-request-radio .p-radiobutton-box {
  opacity: 0;
}

.new-request-radio .p-radiobutton-label {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(109, 32, 119, .8);
  margin: 0;
  display: flex;
  height: 28px;
  color: #fff;
  font-weight: 600;
  align-items: center;
  justify-content: center;
}

.type-request .new-request-radio .p-radiobutton-label {
  position: absolute;
  bottom: -32px;
  width: 100%;
  background: none;
  margin: 0;
  display: flex;
  height: 28px;
  color: $heroTitles;
  font-weight: 600;
  align-items: center;
  justify-content: center;
}

// .new-request-radio .p-radiobutton.p-radiobutton-disabled {  
//   filter: grayscale(100%) !important;
//   opacity: .12;
// }
// .new-request-radio .p-radiobutton.p-radiobutton-disabled + .p-radiobutton-label{  
//   background: rgba(100,100,120,.8);
// }
// .type-request .new-request-radio .p-radiobutton.p-radiobutton-disabled + .p-radiobutton-label {
//   background: none;
//   color: rgba(100, 100, 120, 0.8);
// }



.select .type-request:has(.active) .new-request-radio:not(.active) .p-radiobutton {
  opacity: .4;
  filter: grayscale(100%) !important;
}

.select .type-request:has(.active) .new-request-radio:not(.active) .p-radiobutton:hover {
  opacity: 1;
  filter: grayscale(0) !important;
}

.new-request-specs {
  font-size: 15px;
  font-weight: 600;
  padding: 0px 18px;
  border: solid 1px #fff4d7;
  background-color: #fffdf1;
  line-height: 19px;
  height: 22px;
  display: inline-block;
  margin-left: 20px;
  margin-top: 5px;
  border-radius: 4px;
}

.gap90 {
  gap: 90px;
}

.flex-around {
  display: flex;
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.state-filters {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 0;
  margin-bottom: 8px;
}

.state-filters div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  width: 170px;
  height: 44px;
  border: 2px solid $inputTextColor;
  border-radius: 5px;
}

.state-filters div:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.state-filters div p {
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  color: $inputTextColor;
  background-color: transparent !important;
  display: flex;
  align-items: center;
  gap: 6px;
}

.state-filters div span {
  font-size: 26px;
  color: $inputTextColor;
  background-color: transparent !important;
}

.state-filters div b {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 28px;
  min-width: 28px;
  height: 27px;
  background: $inputTextColor;
  border-radius: 25px;
  font-size: 10px;
  color: $white !important;
}

.state-filters div.active b {
  background: rgba(255, 255, 255, .32) !important;
}

.state-filters div.Created {
  border-color: $createdColor;
}

.state-filters div.Created * {
  color: $createdColor;
  background-color: $createdColor;
}

.state-filters div.Created.active {
  background-color: $createdColor !important;
}

.state-filters div.Created.active * {
  color: $white !important;
}

.state-filters div.Processing {
  border-color: $processingColor;
}

.state-filters div.Processing * {
  color: $processingColor;
  background-color: $processingColor;
}

.state-filters div.Processing.active {
  background-color: $processingColor !important;
}

.state-filters div.Processing.active * {
  color: $white !important;
}

.state-filters div.Downloading {
  border-color: $ongoingColor;
}

.state-filters div.Downloading * {
  color: $ongoingColor;
  background-color: $ongoingColor;
}

.state-filters div.Downloading.active {
  background-color: $ongoingColor !important;
}

.state-filters div.Downloading.active * {
  color: $white !important;
}

.state-filters div.Analyzing {
  border-color: $analyzingColor;
}

.state-filters div.Analyzing * {
  color: $analyzingColor;
  background-color: $analyzingColor;
}

.state-filters div.Analyzing.active {
  background-color: $analyzingColor !important;
}

.state-filters div.Analyzing.active * {
  color: $white !important;
}

.state-filters div.Success {
  border-color: $green ;
}

.state-filters div.Success * {
  color: $green;
  background-color: $green;
}

.state-filters div.Success.active {
  background-color: $green !important;
}

.state-filters div.Success.active * {
  color: $white !important;
}

.state-filters div.Canceled {
  border-color: $cancelColor;
}

.state-filters div.Canceled * {
  color: $cancelColor;
  background-color: $cancelColor;
}

.state-filters div.Canceled.active {
  background-color: $cancelColor !important;
}

.state-filters div.Canceled.active * {
  color: $white !important;
}

.state-filters div.Error {
  border-color: $errorColor;
}

.state-filters div.Error * {
  color: $errorColor;
  background-color: $errorColor;
}

.state-filters div.Error.active {
  background-color: $errorColor !important;
}

.state-filters div.Error.active * {
  color: $white !important;
}

td.status p {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 7px;
  border-radius: 20px;
  /* height: 20px; */
  font-size: 12px;
  font-weight: 600;
  color: $white;
  background-color: $blue;
  width: 116px;
  text-align: center;
  line-height: 12px;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
}

td.status p.Created,
p.actual-status.Created {
  background-color: $createdColor;
}

td.status p.Processing,
p.actual-status.Processing {
  background-color: $processingColor
}

td.status p.Downloading,
p.actual-status.Downloading {
  background-color: $ongoingColor;
}

td.status p.Analyzing,
p.actual-status.Analyzing {
  background-color: $analyzingColor;
}

td.status p.Success,
p.actual-status.Success {
  background-color: $green;
}

td.status p.Canceled,
p.actual-status.Canceled {
  background-color: $cancelColor;
}

td.status p.Error,
p.actual-status.Error {
  background-color: $errorColor;
}


.p-accordion-header-link {
  font-size: 14px;
  color: $headerTextColor;
  padding: 10px 15px 10px 35px;
}

.table-loader-box {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-loader-box>div {
  display: flex;
  align-items: center;
  gap: 5px;
  text-align: center;
  padding: 5px 15px;
  background-color: rgba(255, 255, 255, .8);
  color: $tableThColor;
}

.table-loader-box>div p {
  font-weight: 600;
}

/*** iframe ***/
.content.belender-widget-wrapper.p-1 {
  padding: 4px 0 !important;
}

.content.belender-widget-wrapper.p-1 .flex-col {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.accordion-buttons {
  margin-left: auto;
  padding-right: 15px;
  display: flex;

  >p-button {
    width: 32px;
    height: 32px;
    display: block;
  }

  .p-button-rounded {
    padding: 0;

    &:hover {
      background-color: #ebebeb;
    }

    span {
      padding: 0;
      margin-right: 0;
      font-size: 30px;
      color: #041c2c;
      font-variation-settings: 'wght' 300;
    }
  }
}