.loading-container {
  background-color: rgba(255, 255, 255, 0.85);
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.p-sidebar-550 .loading-container{
  width: 550px;
  left: initial;
}
.p-sidebar-950 .loading-container{
  width: 950px;
  left: initial;
}

.loading-container > .p-element {
  position: absolute;
  left: 0;
  right: 0;
  height: 115px;
  // background: rgba(255,255,255,.75);
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-progress-spinner-svg {
  width: 75px !important;
  height: 75px !important;
}
