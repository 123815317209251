/***PRIMENG TABLES*****/
.p-datatable {

  .p-paginator{
    border-color: #E2E8F0;
    background-color: #fff;
    padding: 0;
    justify-content: flex-end;

    .p-paginator-current {
      height: initial;
      font-size: 12px;
      margin: 0 10px;
      padding: 0 10px;
      cursor: default;
    }

    &.p-paginator-top{
      border-width: 1px 0 0 0;
    }

    &.p-paginator-bottom{
      border-width: 0 0 1px 0;
    }

    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
      background-color: transparent;
      border: 0 none;
      color: #6c757d;
      min-width: 20px;
      height: 20px;
      margin: 5px;
      transition: box-shadow 0.2s;
      border-radius: 50%;

      svg {
        width: 12px;
        height: 12px;
      }

      &:not(.p-disabled):not(.p-highlight):hover {
        background: #fff;
        border-color: transparent;
        color: #343a40;
      }
    }

    .p-paginator-pages{
      .p-paginator-page {
        background-color: transparent;
        border: 0 none;
        color: #6c757d;
        min-width: 20px;
        height: 20px;
        margin: 5px;
        transition: box-shadow 0.2s;
        border-radius: 50%;
        font-size: 11px;
        &.p-highlight {
          background: #aaa;
          border-color: #aaa;
          color: var(--primary-color-text);
          font-weight: 600;
        }
        &:not(.p-highlight):hover {
          background: #fff;
          border-color: transparent;
          color: #343a40;
        }
      }
    }

    p-dropdown{
      .p-dropdown {
        height: 24px;
        margin-top: 0;
        border-width: 1px;
        padding: 0;
        border-radius: 0;
        .p-dropdown-label {
          padding: 0 12px;
          display: flex;
          color: #495057;
          font-size: 12px;
          align-items: center;
          font-weight: 600;
          color: var(--gray-700) !important;
          height: initial !important;
        }
        &:not(.p-disabled),
        &.p-focus{
          box-shadow: initial !important;
          &:hover,
          &:link,
          &:visited,
          &:active,
          &:focus {
            border-color: #c8c8c8 !important;
          }
        }
        .p-dropdown-trigger{
          width: 15px;
          svg{
            width: 11px;
            height: 11px;
            color: #5b5750;
            color: #616161;
            stroke-width: .01rem;
            stroke: #5b5750;
            stroke: #616161;
            margin-right: 10px;
          }
        }
      }
    }

    .p-overlay{
      bottom: 22px !important;
      top: initial !important;
    }

  }

  .p-datatable-header {
    background: transparent;
    color: initial;
    border: none;
    padding: 0;
    font-weight: initial;

    .table-header{
      margin-top: 0;
      margin-bottom: 5px;
      display: flex;
      gap: 10px;
      .table-title{
        font-size: 18px;
        font-weight: 600;
        color: #1E293B;
        margin: 0;
        line-height: 36px;
      }

      .table-filters{
        margin-top: 0 !important;
        padding: 0 15px 0 0 !important;
        border: none !important;
        display: flex !important;
        width: initial;
        justify-content: flex-start !important;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;

        h6{
          width: initial;
          text-transform: none;
          color: #041c2c;
          white-space: nowrap;
          margin: 0;
          font-size: 14px;
          font-weight: 500;
          border-right: 3px solid #041c2c;
          background-color: #f3f3f3;
          height: 22px;
          display: flex;
          align-items: center;
          padding: 0 5px;
        }

        .form-group{
          display: flex !important;
          padding-left: 0;
          padding-right: 0;
          width: initial;
          justify-content: flex-start !important;
          flex-wrap: wrap;
          gap: 10px;
          align-items: center;

          label {
            width: initial;
            text-transform: none;
            color: #4B4B4B;
            white-space: nowrap;
          }

          .filter {
            margin: 0;
            display: flex;
            width: initial;

            &:last-of-type{
              margin-right: 15px;
            }

            div.datepicker-readonly {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              align-items: stretch;
              width: 100px !important;
              background-color: transparent;
              height: 30px;
              padding: 0 8px;
              font-size: 13px;
              font-weight: 400;
              color: #495957;
              line-height: 30px;
              justify-content: center;
            }

            div.datepicker-readonly {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              align-items: stretch;
              width: 100px !important;
              background-color: transparent;
              height: 30px;
              padding: 0 8px;
              font-size: 13px;
              font-weight: 400;
              color: #495957;
              line-height: 30px;
              justify-content: center;
            }

            div.datepicker {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              align-items: stretch;
              width: 115px !important;

              input {
                position: relative;
                flex: 1 1 auto;
                width: 1%;
                margin-bottom: 0;
                display: block;
                height: 30px;
                padding: 0 7px 0 10px;
                font-size: 13px;
                font-weight: 400;
                line-height: normal;
                color: #495057;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 3px;
                transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                border-right: none;
                outline: 0 !important;
              }

              .input-group-append .btn-calendar {
                border: 1px solid #ced4da;
                border-radius: 3px;
                transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                background-color: transparent;
                border-left: none;
                //background-image: url(../images/aedas-sprite.png);
                background-repeat: no-repeat;
                background-position: -81px -17px;
                width: 25px;
                outline: 0 !important;
                height: 30px;
                margin-top: 0;
                margin-right: 0;
                float: none;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                margin-left: -1px;
              }

              input::-webkit-input-placeholder {
                color: #bfbfbf !important;
              }
              :-ms-input-placeholder {
                color: #bfbfbf !important;
              }
            }

            div.select {
              ng-select{
                min-width: initial !important;
              }
            }
          }

          > div .datepicker .top-label {
            text-transform: uppercase;
            color: #919191;
            width: 115px;
            background-color: #f5f5f5;
          }

          div.datepicker {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            width: 115px !important;
            height: 30px;
          }

          div.input-text input {
            width: 100%;
          }
        }

        .btn-clean-filters {
          height: 28px;
          padding: 0 10px 0 5px;
          display: flex;
          align-items: center;
          gap: 5px;
          background-color: #fff;
          border: 1px solid #cdcdcd;
          border-radius: 3px;
          font-size: 13px;
          color: #777;
          margin-left: 0;
          position: relative;
          outline: none;
          cursor: pointer;

          span{
            font-size: 20px;
          }

          &:after{
            display: none;
          }
        }

        .active-filters{
          display: flex;
          align-items: center;
          gap: 15px;
          flex-wrap: wrap;

          .applied-filter{
            display: flex;
            align-items: center;
            gap: 5px;
            .filter-name{
              background-color: #f4f7fa;
              font-size: 12px;
              font-weight: 600;
              border-radius: 3px;
              color: #555;
              display: flex;
              align-items: center;
              padding: 0 5px;
              white-space: nowrap;
            }
            .filter-value{
              font-size: 12px;
              font-weight: 400;
              color: #555;
              white-space: nowrap;
            }
            .clear-filter{
              width: 15px;
              height: 15px;
              color: #777;
              padding: 0;
              padding-inline: 0;
              --bs-gutter-x: 0;
              line-height: 12px;
              border: none;
              border-radius: 50%;
              background: none;
              outline: none;
              opacity: .3;
              transition: all ease-in-out .2s;
              span{
                font-size: 15px;
              }
              &:hover{
                opacity: 1;
                color: red;
              }
            }

          }
        }
      }

      .table-actions{
        position: relative;
        margin-left: auto;
        width: initial;
        top: initial;
        right: initial;
        display: flex;
        flex-wrap: nowrap;
        gap: 10px;
        .line-btn {
          padding: 0 10px 0 10px; /* Ajuste padding para integrar icono "material-symbols" */
          font-size: 13px;
          font-weight: 600;
          height: 30px;
          float: none;
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          white-space: nowrap;
          text-transform: capitalize;
        }
      }
    }
  }

  .p-datatable-footer {
    background: #f8f9fa;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 700;
  }


  .p-datatable-thead {
    > tr {
      > th {
        text-align: left;
        padding: 6px 10px;
        border: 1px solid #E2E8F0;
        border-width: 0 0 1px 0;
        font-size: 13px;
        font-weight: 600;
        color: #444;
        background: #F8FAFC;
        transition: box-shadow 0.2s;

        &.center{
          text-align: center;
          > div{
            justify-content: center;
          }
        }
        &.right{
          text-align: right;
          > div{
            justify-content: flex-end;
          }
        }

        > div{
          display: flex;
          justify-content: space-between;
          align-items: center;

          p-columnfilter{
            margin-left: auto;
            .p-column-filter-menu-button {
              width: 20px;
              height: 20px;
              z-index: 3;
            }
            .p-icon {
              display: inline-block;
              width: 12px;
              height: 12px;
            }

            &.active-filter{
              .p-column-filter-menu-button,
              .p-column-filter-menu-button:hover {
                background: rgb(4 28 44 / 70%);
                color: var(--primary-color-text);
              }
            }
          }
        }
      }
    }
  }

  .p-datatable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #E2E8F0;
    border-width: 0 0 1px 0;
    font-size: 13px;
    font-weight: 700;
    color: #444;
    background: #ededed;
  }

  .p-sortable-column {
    .p-sortable-column-icon {
      color: #444;
      margin-left: 5px;
      width: 11px;
      height: 11px;
    }

    .p-sortable-column-badge {
      border-radius: 50%;
      height: 1.143rem;
      min-width: 1.143rem;
      line-height: 1.143rem;
      color: #007bff;
      background: #EFF6FF;
      margin-left: 5px;
    }

    &:not(.p-highlight):hover {
      background: #e9ecef;
      color: #343a40;

      .p-sortable-column-icon {
        color: #343a40;
      }
    }

    &.p-highlight {
      background: #EFF6FF;
      color: #007bff;

      .p-sortable-column-icon {
        color: #007bff;
      }

      &:hover {
        background: #EFF6FF;
        color: #007bff;

        .p-sortable-column-icon {
          color: #007bff;
        }
      }
    }

    &:focus {
      box-shadow: inset 0 0 0 0.15rem #BFDBFE;
      outline: 0 none;
    }
  }

  .p-datatable-tbody > tr {
    background: #ffffff;
    color: #495057;
    transition: box-shadow 0.2s;

    > td {
      text-align: left;
      border: 1px solid #E2E8F0;
      border-width: 0 0 1px 0;
      padding: 6px 10px;
      font-size: 13px;
      line-height: normal;

      span.ngb-highlight {
        background-color: #ff0;
      }

      .p-row-toggler, .p-row-editor-init, .p-row-editor-save, .p-row-editor-cancel {
        width: 2rem;
        height: 2rem;
        color: #6c757d;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      }

      .p-row-toggler:enabled:hover, .p-row-editor-init:enabled:hover, .p-row-editor-save:enabled:hover, .p-row-editor-cancel:enabled:hover {
        color: #343a40;
        border-color: transparent;
        background: #e9ecef;
      }

      .p-row-toggler:focus, .p-row-editor-init:focus, .p-row-editor-save:focus, .p-row-editor-cancel:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #BFDBFE;
      }

      .p-row-editor-save {
        margin-right: 0.5rem;
      }

      &.center{
        text-align: center;
      }

      &.right{
        text-align: right;
      }

      a {
        color: #4cb4e7;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &:focus {
      outline: 0.15rem solid #BFDBFE;
      outline-offset: 0.15rem;
    }

    &.p-highlight {
      background: #EFF6FF;
      color: #1D4ED8;
    }

    &.p-datatable-dragpoint-top > td {
      box-shadow: inset 0 2px 0 0 #EFF6FF;
    }

    &.p-datatable-dragpoint-bottom > td {
      box-shadow: inset 0 -2px 0 0 #EFF6FF;
    }
  }

  &.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
  }

  .p-column-resizer-helper {
    background: #3B82F6;
  }

  .p-datatable-scrollable-header, .p-datatable-scrollable-footer {
    background: #f8f9fa;
  }

  &.p-datatable-scrollable > .p-datatable-wrapper {
    > .p-datatable-table {
      > .p-datatable-thead,
      > .p-datatable-tfoot {
        background-color: #f8f9fa;
      }
    }

    .p-scroller-viewport > .p-scroller > .p-datatable-table {
      > .p-datatable-thead,
      > .p-datatable-tfoot {
        background-color: #f8f9fa;
      }
    }
  }

  &.p-datatable-gridlines {
    .p-datatable-header {
      border-width: 1px 1px 0 1px;
    }

    .p-datatable-footer {
      border-width: 0 1px 1px 1px;
    }

    .p-paginator-top {
      border-width: 0 1px 0 1px;
    }

    .p-paginator-bottom {
      border-width: 0 1px 1px 1px;
    }

    .p-datatable-thead > tr > th {
      border-width: 1px 0 1px 1px;

      &:last-child {
        border-width: 1px;
      }
    }

    .p-datatable-tbody > tr {
      > td {
        border-width: 1px 0 0 1px;

        &:last-child {
          border-width: 1px 1px 0 1px;
        }
      }

      &:last-child > td {
        border-width: 1px 0 1px 1px;

        &:last-child {
          border-width: 1px;
        }
      }
    }

    .p-datatable-tfoot > tr > td {
      border-width: 1px 0 1px 1px;

      &:last-child {
        border-width: 1px 1px 1px 1px;
      }
    }

    .p-datatable-thead + .p-datatable-tfoot > tr > td, &:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
      border-width: 0 0 1px 1px;

      &:last-child {
        border-width: 0 1px 1px 1px;
      }
    }

    &:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
      border-width: 0 0 0 1px;

      &:last-child {
        border-width: 0 1px 0 1px;
      }
    }
  }
  .p-datatable-loading-icon {
    font-size: 2rem;
  }

  &.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    background: #fcfcfc;

    &.p-highlight {
      background: #EFF6FF;
      color: #1D4ED8;

      .p-row-toggler {
        color: #1D4ED8;

        &:hover {
          color: #1D4ED8;
        }
      }
    }
  }

  &.p-datatable-sm {
    .p-datatable-header, .p-datatable-thead > tr > th, .p-datatable-tbody > tr > td, .p-datatable-tfoot > tr > td, .p-datatable-footer {
      padding: 0.5rem 0.5rem;
    }
  }

  &.p-datatable-lg {
    .p-datatable-header, .p-datatable-thead > tr > th, .p-datatable-tbody > tr > td, .p-datatable-tfoot > tr > td, .p-datatable-footer {
      padding: 1.25rem 1.25rem;
    }
  }
}

.p-column-filter-overlay-menu{
  .ng-select {
    .ng-select-container {
      height: 30px;
      min-height: 30px;

      .ng-value-container .ng-placeholder {
        font-size: 13px;
      }
    }
    &.ng-select-single{
      .ng-select-container {
        .ng-value-container,
        .ng-value-container .ng-value {
          font-size: 13px;
        }
      }
    }
  }

  .p-column-filter-constraint {
    padding: 12px;
    min-width: 290px;
    border-bottom: 1px solid #dee2e6;
  }

  .p-column-filter-buttonbar {
    padding: 0 12px 12px 12px;
    display: none;

    .p-button {
      height: 28px;
      line-height: 24px;
      font-size: 10px;
      font-weight: 700;
      border-radius: 3px;
      padding: 0 15px 0 15px;
      background-color: #3B82F6;
      color: var(--primary-color-text);
      text-transform: uppercase;
      position: relative;
      outline: none;

      &.p-button-outlined{
        background-color: #fff;
        border: 2px solid #8B9DBC;
        color: #677d9f;
      }
    }
  }

}

tbody tr td .btn-table {
  width: 20px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  position: relative;

  .material-symbols-outlined {
    font-size: 20px;
    color: #8B9DBC;
  }
}


