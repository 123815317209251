.p-message {
    backdrop-filter: blur(1.5px);
}

.p-message.p-message-info {
    box-shadow: 0px 4px 8px 0px rgba(59, 130, 246, 0.04);

    .p-message-close {
        &:focus-visible {
            outline-color: $infoMessageIconColor;
        }

        &:hover {
            background: #dbeafe;
        }
    }
}

.p-message.p-message-success {
    box-shadow: 0px 4px 8px 0px rgba(34, 197, 94, 0.04);

    .p-message-close {
        &:focus-visible {
            outline-color: $successMessageIconColor;
        }

        &:hover {
            background: #dcfce7;
        }
    }
}

.p-message.p-message-warn {
    box-shadow: 0px 4px 8px 0px rgba(234, 179, 8, 0.04);

    .p-message-close {
        &:focus-visible {
            outline-color: $warningMessageIconColor;
        }

        &:hover {
            background: #fef9c3;
        }
    }
}

.p-message.p-message-error {
    box-shadow: 0px 4px 8px 0px rgba(239, 68, 68, 0.04);

    .p-message-close {
        &:focus-visible {
            outline-color: $errorMessageIconColor;
        }

        &:hover {
            background: #fee2e2;
        }
    }
}

.p-message.p-message-secondary {
    box-shadow: 0px 4px 8px 0px rgba(74, 85, 103, 0.04);

    .p-message-close {
        &:focus-visible {
            outline-color: $secondaryMessageIconColor;
        }

        &:hover {
            background: $secondaryButtonHoverBg;
        }
    }
}

.p-message.p-message-contrast {
    box-shadow: 0px 4px 8px 0px rgba(2, 6, 23, 0.04);

    .p-message-close {
        &:focus-visible {
            outline-color: $contrastMessageIconColor;
        }

        &:hover {
            background: $contrastButtonHoverBg;
        }
    }
}
