.p-slider {
    .p-slider-handle {
        display: flex !important;
        justify-content: center;
        align-items: center;

        &::before {
            content: '';
            width: 16px;
            height: 16px;
            display: block;
            background-color: #ffffff;
            border-radius: 50%;
            box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
        }

        &:focus-visible {
            outline: 0 none;
            box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--p-focus-ring-color), 0 1px 2px 0 rgba(0, 0, 0, 1);
        }
    }
}
